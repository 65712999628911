import React, {useCallback, useEffect, useState, ChangeEvent} from 'react';
import http from "../../config/http-common";
import {Spinner} from "react-bootstrap";


interface User {
    Id: string;
    UserId: string;
    Name_lastname: string;
    Phone: string;
    Email: string;
    Address: string;
    RegNr: string;
    Company: string;
    PvnNr: string;
    CompanyAddress: string;
    WWW: string;
}

interface Unit {
    UnitId: string;
    UnitName: string;
    Unit: string;
    UnitPrice: number;
    Quantity: number;
    Price: number;
}

interface Product {
    ProductId: string;
    ProductName: string;
    SKU: string;
    UnitList: Unit[];
}

interface Item {
    CategoryId: string;
    Products: Product[];
    TotalPrice: number;
    TotalDiscount: number;
}

interface Basket {
    ID: string;
    UserID: string;
    Items: Item[];
    Status: number;
    CreatedAt: Date;
    UpdatedAt: Date;
}

interface Orders {
    user: User | null;
    basket: Basket;
    totalPrice: number;
    totalDiscount: number;
}

const OrderStatus: Record<number, string> = {
    0: 'Process',
    1: 'Ordered',
    2: 'Delivered',
    3: 'Payment',
    4: 'Canceled',
}

const Pasutijumi: React.FC<{}> = props => {
    const renderKey = useCallback(() => Math.random().toString(), []);
    const [isLoading, setIsLoading] = useState(true);
    const token = localStorage.getItem('token');
    const [orders, setOrders] = useState<Orders[]>([]);
    const [processOrders, setProcessOrders] = useState<Orders[]>([]);
    const [selectedStatus, setSelectedStatus] = useState<number | null>(null);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = () => {
        setIsLoading(true);
        http.get('/api/orders', {
            headers: {
                'Token': token
            }
        })
            .then(response => {
                //setOrders(response.data.order)
                setOrders(response.data.order.filter((order: { basket: { Status: number; }; }) => order.basket.Status != 0));
                setProcessOrders(response.data.order.filter((order: { basket: { Status: number; }; }) => order.basket.Status == 0));
            })
            .catch(error => {
                console.log(error);
            }).finally(() => {
            setIsLoading(false);
        });
        console.log(processOrders)
    }

    const handleStatusChange = (event: ChangeEvent<HTMLSelectElement>) => {
        event.stopPropagation(); // Prevents outer events (such as collapsable close) to be triggered
        event.nativeEvent.stopImmediatePropagation(); // Prevents other events in the same element to be triggered
        setSelectedStatus(Number(event.target.value));
    };

    const handleSelectedStatus = (ID: string) => {


            if (selectedStatus !== null) {
                http.put('/api/orders/status', {
                    ID: ID,
                    Status: selectedStatus
                }, {
                    headers: {
                        'Token': token
                    }
                })
                    .then(response => {
                        //setOrders(response.data.order)
                            setOrders(response.data.order.filter((order: { basket: { Status: number; }; }) => order.basket.Status != 0));
                        setProcessOrders(response.data.order.filter((order: { basket: { Status: number; }; }) => order.basket.Status === 0));
                    })
                    .catch(error => {
                        console.log(error);
                    });
                console.log(processOrders)
            }

    };

    return (
        <div>
        <div className="container mt-4">

            {isLoading && (
                <div style={{
                    position: 'fixed',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 9999
                }}>
                    <Spinner animation="border"/>
                </div>
            )}

            <div className="row">
                <div className="col-md-3">
                    <h2>Pasūtījumi </h2> <i role="button" className="bi bi-arrow-clockwise m-1" onClick={loadData}></i>
                </div>
            </div>

            <div className="accordion" id="accordionExample">

                {orders && orders.map((order, index) => (
                    <div className="accordion-item">
                        <h2 className="accordion-header" id={`heading${index}`}>
                            <button className={`accordion-button collapsed `}
                                    type="button" data-bs-toggle="collapse"
                                    data-bs-target={`#collapse${index}`}
                                    aria-expanded="true"
                                    aria-controls={`collapse${index}`}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-3">
                                            <b>{order.user?.Company || order.user?.Name_lastname}</b>
                                        </div>
                                        <div className="col-3">
                                            Statuss: <b>{OrderStatus[order.basket.Status]}</b>
                                        </div>
                                        <div className="col-3">
                                            Statusa
                                            datums: <b>{new Date(order.basket.CreatedAt).toLocaleDateString()}</b>
                                        </div>
                                        <div className="col-3">
                                            {order.basket.Items &&
                                                <>
                                                    <span>Total: </span>
                                                    {order.totalDiscount !== 0 && (
                                                        <span style={{color: 'red'}}><del>{(order.totalPrice).toFixed(2)} € </del></span>
                                                    )}
                                                    <span className="ms-2"
                                                          style={{fontSize: '1em'}}><b>{(order.totalPrice - order.totalDiscount).toFixed(2)} € </b></span>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>

                            </button>
                        </h2>
                        <div id={`collapse${index}`} className="accordion-collapse collapse"
                             aria-labelledby={`heading${index}`}
                             data-bs-parent="#accordionExample">
                            <div className="accordion-body ">
                                <section>
                                    <div className="row">
                                        <div className="col-4">
                                            <b>{order.user?.Company || order.user?.Name_lastname}</b>
                                        </div>
                                        <div className="col-5">
                                            E-Pasts: <b>{order.user?.Email}</b> Tālrunis: <b>{order.user?.Phone}</b>
                                        </div>
                                        <div className="col-3">
                                            Reg.Nr: <b>{order.user?.RegNr}</b>
                                        </div>
                                    </div>
                                    <hr/>
                                </section>
                                <section>
                                    {order.basket.Items && order.basket.Items.map((item, itemIndex) => (
                                        <div key={itemIndex}>
                                            {item.Products.map(prod => (
                                                <div className="row">
                                                    <div className="col-7">{prod.ProductName} (SKU:{prod.SKU})</div>
                                                    <div className="col-5">
                                                        {prod.UnitList.map(unit => (
                                                            <div className="row">
                                                                <div
                                                                    className="col">{unit.UnitName} {unit.Unit} - {(unit.UnitPrice).toFixed(2)} €
                                                                    | qt: <b>{unit.Quantity}</b> | Total:
                                                                    <b>{(unit.Price).toFixed(2)} €</b>
                                                                </div>
                                                            </div>
                                                        ))}

                                                    </div>
                                                    <hr/>
                                                </div>

                                            ))}

                                        </div>
                                    ))}

                                    <hr/>
                                    <div className="row">
                                        <div className="col-7">
                                            <div className="row">
                                                <div className="col-2 ms-1">
                                                    <label>Statuss:</label>
                                                </div>
                                                <div className="col-5">
                                                    <select
                                                        className="form-control"
                                                        id="Status" name="Status"
                                                        onChange={handleStatusChange}>
                                                        {
                                                            Object.entries(OrderStatus).map(([key, value]) =>
                                                                <option key={key} value={key}
                                                                        selected={order.basket.Status === Number(key)}>{value}</option>
                                                            )
                                                        }
                                                    </select>
                                                </div>
                                                <div className="col-4">
                                                    <button className="btn btn-success btn-block"
                                                            onClick={() => handleSelectedStatus(order.basket.ID)}>
                                                        Mainīt
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-5 text-center">
                                            {/* <button className="btn btn-success btn-block">
                                                Lejuplādēt PDF rēķinu
                                            </button>*/}
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                ))}


            </div>


        </div>

        <div className="container mt-4">

        <div className="row">
            <div className="col-md-6">
                <h2>Pasūtījumi kurus Klients vel gatavo! </h2> <i role="button" className="bi bi-arrow-clockwise m-1" onClick={loadData}></i>
            </div>
        </div>

        <div className="accordion" id="accordionExample1">
            {processOrders && processOrders.map((order, index) => (
                <div className="accordion-item">
                    <h2 className="accordion-header" id={`heading${index}p`}>
                        <button className={`accordion-button collapsed `}
                                type="button" data-bs-toggle="collapse"
                                data-bs-target={`#collapse${index}p`}
                                aria-expanded="true"
                                aria-controls={`collapse${index}p`}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-3">
                                        <b>{order.user?.Company || order.user?.Name_lastname}</b>
                                    </div>
                                    <div className="col-3">
                                        Statuss: <b>{OrderStatus[order.basket.Status]}</b>
                                    </div>
                                    <div className="col-3">
                                        Statusa datums: <b>{new Date(order.basket.CreatedAt).toLocaleDateString()}</b>
                                    </div>
                                    <div className="col-3">
                                        {order.basket.Items &&
                                            <>
                                                <span>Total: </span>
                                                {order.totalDiscount !== 0 && (
                                                    <span style={{color: 'red'}}><del>{(order.totalPrice).toFixed(2)} € </del></span>
                                                )}
                                                <span className="ms-2"
                                                      style={{fontSize: '1em'}}><b>{(order.totalPrice - order.totalDiscount).toFixed(2)} € </b></span>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>

                        </button>
                    </h2>
                    <div id={`collapse${index}p`} className="accordion-collapse collapse"
                         aria-labelledby={`heading${index}p`}
                         data-bs-parent="#accordionExample">
                        <div className="accordion-body ">
                            <section>
                                <div className="row">
                                    <div className="col-4">
                                        <b>{order.user?.Company || order.user?.Name_lastname}</b>
                                    </div>
                                    <div className="col-5">
                                        E-Pasts: <b>{order.user?.Email}</b> Tālrunis: <b>{order.user?.Phone}</b>
                                    </div>
                                    <div className="col-3">
                                        Reg.Nr: <b>{order.user?.RegNr}</b>
                                    </div>
                                </div>
                                <hr/>
                            </section>
                            <section>
                                {order.basket.Items && order.basket.Items.map((item, itemIndex) => (
                                    <div key={itemIndex}>
                                        {item.Products.map(prod => (
                                            <div className="row">
                                                <div className="col-7">{prod.ProductName} (SKU:{prod.SKU})</div>
                                                <div className="col-5">
                                                    {prod.UnitList.map(unit => (
                                                        <div className="row">
                                                            <div
                                                                className="col">{unit.UnitName} {unit.Unit} - {(unit.UnitPrice).toFixed(2)} €
                                                                | qt: <b>{unit.Quantity}</b> | Total:
                                                                <b>{(unit.Price).toFixed(2)} €</b>
                                                            </div>
                                                        </div>
                                                    ))}

                                                </div>
                                                <hr/>
                                            </div>

                                        ))}

                                    </div>
                                ))}

                                <hr/>
                                <div className="row">
                                    <div className="col-7">
                                        <div className="row">
                                            <div className="col-2 ms-1">
                                                <label>Statuss:</label>
                                            </div>
                                            <div className="col-5">
                                                <select
                                                    className="form-control"
                                                    id="Status" name="Status"
                                                    onChange={handleStatusChange}>
                                                    {
                                                        Object.entries(OrderStatus).map(([key, value]) =>
                                                            <option key={key} value={key}
                                                                    selected={order.basket.Status === Number(key)}>{value}</option>
                                                        )
                                                    }
                                                </select>
                                            </div>
                                            <div className="col-4">
                                                <button className="btn btn-success btn-block"
                                                        onClick={() => handleSelectedStatus(order.basket.ID)}>
                                                    Mainīt
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-5 text-center">
                                        {/* <button className="btn btn-success btn-block">
                                                Lejuplādēt PDF rēķinu
                                            </button>*/}
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            ))}


        </div>


    </div>
        </div>
)
    ;
}

export default Pasutijumi;