import React, { useState} from 'react';
import './Register.css';
import http from "../config/http-common";

type User = {
    Id:string,
    Username:string,
    Password:string,
}

const Register: React.FC = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [isPasswordMatch, setIsPasswordMatch] = useState(false);
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");

    const handleEmailChange = (e: any) => {
        setEmail(e.target.value);
        setIsEmailValid(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/.test(e.target.value));
    };

    const handlePasswordChange = (e: any) => {
        setPassword(e.target.value);
        setIsPasswordValid(/^(?=.*[0-9])(?=.*[-+_!@#$%^&*.,?]).{8,}/.test(e.target.value));
    };

    const handleConfirmPasswordChange = (e: any) => {
        setConfirmPassword(e.target.value);
        setIsPasswordMatch(e.target.value === password);
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        if(isEmailValid && isPasswordValid && isPasswordMatch) {
            const user: User = {
                Id: '',
                Username: email,
                Password: password,
            };

            await http.post('/api/register', user).then(response => {
                if (response.data.Status === "ok") {
                    window.location.href = '/login';
                } else {
                    setErrorMessage(response.data.Message);
                }
            });
        }
    }

    return (
        <>

        <div className="container login-container">
            <div className="row ">
                <div className="col-md-4 logo text-center">
                    <img className="m-2" src="KERMAN-LOGO_MELNS.png" alt="Logo" style={{maxWidth: '150px'}} />
                </div>
                <div className="col-md-8 bg-white p-2 login_form">
                    <h2 className="text-center">User Registration</h2>
                    {errorMessage && (
                        <div className="alert alert-warning alert-dismissible fade show" role="alert">
                            <strong>{errorMessage}</strong>
                        </div>
                    )}


                    <form id="registrationForm" onSubmit={handleSubmit} className="p-0">
                        <div className="form-group">
                            <label form="username">Email</label>
                            <input type="email" className="form-control" id="username" name="username"
                                   onChange={handleEmailChange}
                                   required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"  title=" Please enter a valid email address!"/>
                            {!isEmailValid && <small id="emailHelp" className="form-text  invalid"> Please enter a valid email address!</small>}
                        </div>
                        <div className="form-group">
                            <label form="password">Password</label>
                            <input type="password" className="form-control" id="password" name="password"
                                   value={password} onChange={handlePasswordChange} required/>
                            {!isPasswordValid && <small id="passwordHelp" className="form-text  invalid">he password must contain at least 8 characters, numbers and special symbols!</small>}
                        </div>
                        <div className="form-group">
                            <label form="confirm-password">Confirm password</label>
                            <input type="password" className="form-control" id="confirm-password"
                                   value={confirmPassword} onChange={handleConfirmPasswordChange} required/>
                            {!isPasswordMatch && <small id="confirmHelp" className="form-text invalid">Confirm password is not the same as password!</small>}
                        </div>
                        <div className="text-center m-2">
                            <button type="submit" className="btn btn-primary btn-block" id="submitButton"
                                    disabled={!isPasswordValid || !isPasswordMatch}>Sign up</button>
                        </div>

                    </form>
                    <p className="text-center">
                        Already have an account? <a href="/login">Log in</a>
                    </p>
                </div>
            </div>
        </div>
        </>
    );
};

export default Register;