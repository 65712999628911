import React, {ReactNode, useCallback, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {getUserRoleFromToken} from "../services/authService";
import http from "../config/http-common";
import Modal from 'react-bootstrap/Modal';
import LietotajsModal from "../components/admin/LietotajsModal";

interface UserLayoutProps {
    children: ReactNode;
}



interface BasketItem {
    BasketId: string;
    TotalProducts: number;
    TotalPrice: number;
    TotalDiscount: number;
    TotalQuantity: number;
    BasketItems: BasketItems[]
    Message: string;
}


interface BasketItems {
    Products: Product[];
}

interface Product {
    ProductName: string;
    UnitList: Unit[];
}

interface Unit {
    UnitName: string;
    Unit: string;
    UnitPrice: number;
    Quantity: number;
    Price: number;
}

type User = {
    Id: string,
    UserId: string,
    Name_lastname: string,
    Phone: string,
    Email: string,
    Address: string,
    RegNr: string,
    Company: string,
    PvnNr: string,
    CompanyAddress: string,
    WWW: string
};

const initUser: User = {
    Id: '',
    UserId: '',
    Name_lastname: '',
    Phone: '',
    Email: '',
    Address: '',
    RegNr: '',
    Company: '',
    PvnNr: '',
    CompanyAddress: '',
    WWW: ''
};

const UserLayout: React.FC<UserLayoutProps> = ({children}) => {
    const token = localStorage.getItem('token');
    const [showModal, setShowModal] = useState(false);
    const [showUserModel, setShowUserModel] = useState(false);
    const [fechDataChild, setFechDataChild] = useState(false);

    const [userRole, setUserRole] = useState('');
    const [basketItems, setBasketItems] = useState<BasketItem | null>(null);
    const [userInfo, setUserInfo] = useState<User>(initUser);
    const navigate = useNavigate();

    const handleShowUserModel = () => setShowUserModel(true);
    const handleClose = () => setShowUserModel(false);


    useEffect(() => {
        return setUserRole(getUserRoleFromToken());
    }, []);


    const fatchUserInfo = useCallback(() => {
        const token = localStorage.getItem('token');
        http.get('/api/public/user', {
            "headers": {
                'Token': token
            }
        })
            .then((response) => {
                setUserInfo(response.data);
            })
            .catch((error) => {
                console.error(`Error fetching data: ${error}`);
            });
    }, [])


    const fetchBasketData = useCallback(() => {
        const token = localStorage.getItem('token');
        http.get('/api/public/basket', {
            "headers": {
                'Token': token
            }
        })
            .then((response) => {
                setBasketItems(response.data);

            })
            .catch((error) => {
                console.error(`Error fetching data: ${error}`);
            });
    }, []);

    const fetchBasketItems = useCallback(() => {
        if (basketItems && basketItems.BasketId) {
            const token = localStorage.getItem('token');
            http.get(`/api/public/basket/${basketItems.BasketId}`, {
                "headers": {
                    'Token': token
                }
            })
                .then((response) => {
                    setBasketItems(response.data);
                })
                .catch((error) => {
                    console.error(`Error fetching data: ${error}`);
                });
        }
    }, [basketItems]);

    const handleCancelOrder = () => {
        http.delete(`/api/public/basket/${basketItems?.BasketId}`, {
            "headers": {
                'Token': token
            }
        })
            .then((response) => {
                setBasketItems(response.data);
                setFechDataChild(true)
            })
            .catch((error) => {
                console.error(`Error fetching data: ${error}`);
            }).finally(()=>{
            setFechDataChild(false)
        });
    };

    const handleProcessModel = () => {
        http.post(`/api/public/basket/${basketItems?.BasketId}`, basketItems?.BasketId, {
            "headers": {
                'Token': token
            }
        })
            .then((response) => {
                setBasketItems(response.data);
                setFechDataChild(true)
            })
            .catch((error) => {
                console.error(`Error fetching data: ${error}`);
            }).finally(()=>{
            setFechDataChild(false)
        });
    };

    useEffect(() => {
        fetchBasketData();
        fatchUserInfo();
    }, [fetchBasketData, fatchUserInfo, children]);

    const handleShowModal = () => {
        fetchBasketItems();
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setFechDataChild(true)
        setShowModal(false);

    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/your-route');
    };

    function handleAdmin() {
        navigate('/admin');
    }

    return (
        <div>
            <Modal className="modal-lg" show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Products in Basket</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {basketItems?.Message && <p>{basketItems?.Message}</p>}

                    {basketItems && !basketItems?.Message &&
                        <>
                            {basketItems?.BasketItems?.map(item => (
                                item.Products.map(product => (
                                    <>
                                        <div className="row">
                                            <div className="col-7">
                                                {product.ProductName}
                                            </div>
                                            <div className="col-5">
                                                {product.UnitList.map(unit => {
                                                    return (
                                                        <div className="row">
                                                            <div className="col">
                                                                {unit.UnitName} {unit.Unit} - {(unit.UnitPrice).toFixed(2)} €
                                                                |
                                                                qt: {unit.Quantity} |
                                                                Total: {(unit.Price).toFixed(2)} €
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                        <hr/>
                                    </>
                                ))
                            ))}

                            <div className="row text-end m-2">
                                <div className="col-12">

                                    <span>Basket Total: </span>
                                    {basketItems?.TotalDiscount !== 0 && (
                                        <span style={{color: 'red'}}><del>{(basketItems?.TotalPrice).toFixed(2)} € </del></span>
                                    )}
                                    <span className="ms-2"
                                          style={{fontSize: '1em'}}><b>{(basketItems?.TotalPrice - basketItems?.TotalDiscount).toFixed(2)} € </b></span>

                                </div>

                            </div>
                            <hr/>
                            <div className="row text-center">
                                <div className="col-6">
                                    <button className="btn btn-warning" onClick={handleCancelOrder}>Cancel Order
                                    </button>
                                </div>
                                <div className="col-6">
                                    {userInfo?.Id !=="" && (
                                        <button className="btn btn-success" onClick={handleProcessModel}>Process Order
                                        </button>
                                    )}
                                    {userInfo?.Id === "" && (
                                        <span style={{color: 'red'}}>Add User Data</span>
                                    )}

                                </div>
                            </div>
                        </>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary" onClick={handleCloseModal}>Close</button>
                </Modal.Footer>
            </Modal>

            <LietotajsModal show={showUserModel} onClick={handleClose} selectedUser={userInfo}
                            reloadData={fatchUserInfo}/>

            <div className="fluid px-2 border" style={{backgroundColor: 'white'}}>
                <nav className="navbar navbar-expand-sm ">

                    <ul className="nav col-md-4 col-sm-12 mb-2 justify-content-center mb-md-0">
                        {userRole === "Admin" &&
                            <li className="nav-item">
                                <button className="nav-link px-2" id="catalogs" onClick={handleAdmin}>Admin
                                </button>
                            </li>
                        }
                    </ul>

                    <div className="col-md-4 col-sm-12 ms-3 text-center">
                        <img src="KERMAN-LOGO_MELNS.png" alt="Logo" style={{maxHeight: '25px'}}/>
                    </div>


                    <div className="col-md-4 col-sm-12 text-end me-1">
                    <i role="button" className="bi bi-person-circle me-2" onClick={handleShowUserModel}>
                            <span className=" me-2 ms-1">{userInfo?.Company || userInfo?.Name_lastname ||
                                <span style={{color: 'red'}}>Add User Data</span>} </span></i>

                        <i role="button" className="bi bi-basket me-2" onClick={handleShowModal}></i>
                        {basketItems &&
                            <>
                                <span>Total: </span>
                                {basketItems?.TotalDiscount !== 0 && (
                                    <span style={{color: 'red'}}><del>{(basketItems?.TotalPrice).toFixed(2)} € </del></span>
                                )}
                                <span className="ms-2"
                                      style={{fontSize: '1em'}}><b>{(basketItems?.TotalPrice - basketItems?.TotalDiscount).toFixed(2)} € </b></span>
                            </>
                        }

                        <button className="btn btn-warning me-3" onClick={handleLogout}>Logout</button>
                    </div>

                </nav>
            </div>
            <div className="container-fluid">
                <div className="row flex-nowrap">
                    <div className="col py-3">
                        {React.cloneElement(children as React.ReactElement, {reloadBasket: fetchBasketData, doFetchData: fechDataChild})}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserLayout;