import React, {useCallback, useEffect, useState} from 'react';
import http from "../../config/http-common";
import { Button, Modal, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import ProductComponent from "./ProductComponent";
import CatalogsModalV1 from "./CatalogsModalV1";
import { ErrorBoundary } from 'react-error-boundary';
import ProductModalV1 from "./ProductModalV1";
import discountTypesData from "../../resources/discount.json";
import {logout, UseRefreshToken} from "../../services/authService";

type Discount = {
    discountId: string;
    discountType: string;
};

type Category = {
    Id: string,
    Name: string,
    Unit: string,
    Discount: string,
    Products: Product[] | null,
};

type Product = {
    ID: string,
    CategoryID: string,
    Name: string,
    SKU: string,
    ImageLink: string,
    Units: Unit[],
};

type Unit = {
    Id: string,
    Name: string,
    Price: number,
};

type ModalInfo = {
    show: boolean,
    mode: "edit" | "create"
};
type ProductModalInfo = {
    show: boolean,
    editMode: boolean
}

const Catalog: React.FC = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true); // add loading state

    const [showProductModal, setShowProductModal] = useState<ProductModalInfo>({show: false, editMode: false});
    const [modalInfo, setModalInfo] = useState<ModalInfo>({ show: false, mode: 'create' });
    const [categoryList, setCategoryList] = useState<Category[] | null>(null); // set initial state to null
    const [selectedCategory, setSelectedCategory] = useState<Category | null>(null);
    const [products, setProducts] = useState<Product[] | null>(null);
    const renderKey = useCallback(() => Math.random().toString(), []);
    const handleShowCatalogsModalClose = () => {setModalInfo({ show: false, mode: 'create' });};
    const handleShowProductModalClose = () => setShowProductModal({show:false, editMode:false});
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);

    const discountTypes = discountTypesData;
    const getDiscountType = useCallback(
        (id: string) => {
            const discount = discountTypes.find((discount) => discount.discountId === id);
            return discount ? discount.discountType : undefined;
        },
        [discountTypes]
    );
    const fetchCatalogList = useCallback(async () => {
        setIsLoading(true); // set loading state to true before fetch
        const token = localStorage.getItem('token');

        await http.get('/api/category', {
            headers: {
                'Token': token
            }
        }).then(response => {
            setCategoryList(Array.isArray(response.data) ? response.data : null); // checking if response data is array before setting it
        }).catch((error: { response: { status: number; }; }) => {

            if (error.response) {
                const status = error.response.status;
                if (status === 401) {
                    UseRefreshToken().then(() => {
                        fetchCatalogList();
                    });
                }
            } else {
                logout();
            }
        }).finally(() => {
            setIsLoading(false); // set loading state to false after fetch
        });
    }, []);

    useEffect(() => {
        fetchCatalogList();
    }, [fetchCatalogList, navigate]);

    const handleCategoryDelete = async (id: string) => {
        const token = localStorage.getItem('token');
        try {
            await http.delete('/api/category', {
                headers: {
                    'Token': token
                },
                params: { categoryID: id }
            });
            if(categoryList) {
                setCategoryList(categoryList.filter(category => category.Id !== id));
            }
        } catch (error) {
            logout();
        }
    };

    const askDeleteConfirmation = () => {
        setShowDeleteConfirmationModal(true);
    };

    const handleConfirmDelete = () => {
        if (selectedCategory) { // Check that selectedCategory is not null
            handleCategoryDelete(selectedCategory.Id);
            setShowDeleteConfirmationModal(false);
        }
    };

    const handleCancelDelete = () => {
        setShowDeleteConfirmationModal(false);
    };

    const handleNewCatalogs = () => {
        setModalInfo({ ...modalInfo, show: true, mode: 'create' });
    };

    const handleEditCatalogs = (event: React.MouseEvent) => {
        event.stopPropagation(); // stops the event from propagating to the outer elements
        setModalInfo({ ...modalInfo, show: true, mode: 'edit' });
    };

    const handleOnSuccess = () => {
        //reload catalog list after successful operation
        fetchCatalogList();
    };

    const handleNewProduct = () => {
        setShowProductModal({show:true, editMode:false})
    };

    const handleCategoryClick = async (id: string) => {
        setIsLoading(true); // set loading state to true before fetch
        if(categoryList){
            const selectedCat = categoryList.find(category => category.Id === id);

        setSelectedCategory(selectedCat || null);
        setProducts(null);
            const token = localStorage.getItem('token');
        await http.get('/api/product', {
            headers: {
                'Token': token
            },
            params: { categoryID: selectedCat?.Id }
        }).then((response) => {
            setProducts(response.data || null);
        }).catch((error: { response: { status: number; }; }) => {
            if (error.response) {
                const status = error.response.status;
                if (status === 401) {
                    UseRefreshToken().then(() => {
                        handleCategoryClick(id);
                    });
                }
            } else {
                navigate('/login');
            }
        }).finally(() => {
            setIsLoading(false); // set loading state to false after fetch
        });
        }
    };

    return (
        <ErrorBoundary FallbackComponent={() => <div>Error occurred, redirecting...</div>} onReset={() => navigate('/login')}>
        <div className="container mt-4" key={renderKey()}>

            {/* Add absolute-centered spinner for loading state */}
            {isLoading && (
                <div style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
                    <Spinner animation="border" />
                </div>
            )}

            <div className="row">
                <div className="col-md-3">
                    <h2>Katalogs</h2>
                </div>
            </div>



            <div className="row justify-content-end">
                <div className="col-md-4 align-content-end text-end">
                    <button className="btn btn-primary" onClick={() => handleNewCatalogs()}>
                        Jauna Kategorija
                    </button>
                </div>
            </div>

            <div className="row my-2">

                    {/* The list of catalogs */}
                {categoryList && categoryList.length > 0 && categoryList.map((catalog, index) => (

                    <>
                    <div className="col-12" >
                        <div key={index} className="col-12">
                            <div role="button" className="card" onClick={() => handleCategoryClick(catalog.Id)}>
                                <h5 className={`card-header ${selectedCategory?.Id === catalog.Id ? 'bg-success' : ''}`}>{catalog.Name}</h5>

                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-5 col-12">
                                            <div className="col">Mērvienība: <b>{catalog.Unit}</b></div>
                                        </div>
                                        <div className="col-lg-5 col-12">
                                            <div className="col">Atlaides
                                                veids: <b>{getDiscountType(catalog.Discount)}</b></div>
                                        </div>
                                        <div className="col-lg-2 col-12 text-end">
                                            {selectedCategory?.Id === catalog.Id &&
                                                <i className="bi bi-pencil m-1" onClick={handleEditCatalogs}></i>}
                                            {selectedCategory?.Id === catalog.Id && products == null &&
                                                <i className="bi bi-x-lg m-1" onClick={askDeleteConfirmation}></i>}

                                            <Modal show={showDeleteConfirmationModal} onHide={handleCancelDelete}>
                                                <Modal.Header closeButton>
                                                    <Modal.Title>Dzēšanas apstiprinājums</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>Vai vēlaties dzēst izvēlēto Kategoriju?</Modal.Body>
                                                <Modal.Footer>
                                                    <Button variant="secondary" onClick={handleCancelDelete}>Nē</Button>
                                                    <Button variant="primary" onClick={handleConfirmDelete}>Jā</Button>
                                                </Modal.Footer>
                                            </Modal>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                        <hr className="mt-2 mb-3"/>


                        <div className="row">
                            <div className="col-12 text-end">
                                {selectedCategory?.Id === catalog.Id && (
                                    <button type="submit" className="btn btn-primary mb-2" id="openProduct"
                                            onClick={() => handleNewProduct()}>Jauns Produkts
                                    </button>
                                )}

                            <div className="row">
                                    {selectedCategory?.Id === catalog.Id && products && !isLoading && products.map((product, index) => (
                                        <ProductComponent product={product} categoryUnit={selectedCategory?.Unit || ''}
                                                          handleCategoryClick={handleCategoryClick} key={index}/>
                                    ))}

                            </div>
                        </div>
                        </div>
                        </div>

                    </div>
                        </>
                    ))}


            </div>

            <CatalogsModalV1 key={renderKey()} show={modalInfo.show} mode={modalInfo.mode} onClick={handleShowCatalogsModalClose} onSuccess={handleOnSuccess} selectedCategory={selectedCategory!} />



            <ProductModalV1
                productToEdit={null || null}
                editMode={false}
                show={showProductModal.show}
                key={renderKey()}
                selectedCategoryUnit={selectedCategory?.Unit || ''}
                selectedCategoryId={selectedCategory?.Id || ''}
                closeHandler={handleShowProductModalClose}
                reloadCatalog={() => handleCategoryClick(selectedCategory?.Id || '')}
            />
        </div>
        </ErrorBoundary>
    );
};

export default Catalog;