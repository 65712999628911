import React, {useEffect, useState} from 'react';
import http from "../../config/http-common";

type Product = {
    ID: string,
    CategoryID: string,
    Name: string,
    SKU: string,
    ImageLink: string,
    Units: Unit[],
};

type Unit = {
    Id: string,
    Name: string,
    Price: number | string,
};

interface ModalProps {
    editMode: boolean,
    productToEdit: Product | null,
    show: boolean,
    closeHandler: () => void,
    selectedCategoryUnit: string,
    selectedCategoryId: string,
    reloadCatalog: () => void,
}

const ProductModalV1: React.FC<ModalProps> = ({show,
                                                  closeHandler,
                                                  editMode,
                                                  productToEdit,
                                                  selectedCategoryUnit,
                                                  selectedCategoryId,
                                                  reloadCatalog}) => {
    const [formData, setFormData] = useState<Product | null>(null);
    const imgUrl = formData?.ImageLink || '';

    const closeAndCleanForm = () => {
        setFormData(null);
        closeHandler();
    };

    useEffect(() => {
        if (editMode && productToEdit){
            const productCopy: Product = {...productToEdit, Units: productToEdit.Units.map(unit => ({...unit}))};
            setFormData(productCopy);
        } else {
            setFormData({
                CategoryID: "", ID: "", ImageLink: "", Name: "", SKU: "", Units: [{Id:"", Name:'', Price: ''}]
            });
        }
    }, [editMode, productToEdit]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        if (!formData) return;
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleUnitChange = (index: number, field: string, value: string|number) => {
        if (!formData) return;

        const newUnits = [...formData.Units];
        newUnits[index] = {...newUnits[index], [field]: value};
        setFormData({...formData, Units: newUnits});
    }

    const addRow = () => {
        if (!formData) return;

        const updatedUnits = [...formData.Units, {Id: "", Name: "", Price: ''}];
        setFormData({...formData, Units: updatedUnits});
    };

    const deleteRow = () => {
        if (!formData) return;

        const updatedUnits = [...formData.Units];
        updatedUnits.pop();
        setFormData({...formData, Units: updatedUnits});
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const token = localStorage.getItem('token');

        if (!formData) return;

        const formDataCopy: Product = {...formData,
            ID: formData.ID,
            CategoryID: selectedCategoryId,
            Name:formData.Name,
            SKU:formData.SKU,
            ImageLink:formData.ImageLink,
            Units: formData.Units.map(unit => ({...unit}))};

        if(editMode){
            await http.put('/api/product', formDataCopy, {
                headers: {
                    'Token': token
                }
            }).then(response => {
                closeAndCleanForm();
                reloadCatalog()
            }).catch(error => {
                    console.error(error);
            });
        } else {
            await http.post('/api/product', formDataCopy, {
                headers: {
                    'Token': token
                }
            }).then(response => {
                closeAndCleanForm();
                reloadCatalog()
            }).catch(error => {
                    console.error(error);
            });
        }
    };

    if (!formData) {
        return null;
    }

    return (
        <div className="modal" tabIndex={-1} style={{display: show ? "block" : "none"}}>
            <div className="modal-dialog modal-lg modal-dialog-centered ">
                <div className="modal-content bg-light">
                    <div className="modal-header">
                        <h5 className="modal-title">{editMode ? "Produkta koriģēšana" : "Jauns Produkts"}</h5>
                        <button type="button" className="btn-close" onClick={closeAndCleanForm}></button>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="modal-body">

                            <input className="form-control m-1" type="text" id="Name" name="Name"
                                   defaultValue={formData?.Name}
                                   placeholder="Nosaukums"
                                   onChange={handleChange}/>

                            <input className="form-control m-1" type="text" id="ImageLink" name="ImageLink"
                                   defaultValue={imgUrl}
                                   placeholder="Attēla saite"
                                   onChange={handleChange}/>

                            <div className="row">
                                <div className="col-md-4">
                                    <img id="imagePreview" src={imgUrl} alt=""
                                         className="img-fluid"/>
                                </div>

                                <div className="col-md-8">
                                    <input className="form-control m-1" type="text" id="SKU" name="SKU"
                                           placeholder="SKU"
                                           defaultValue={formData?.SKU}
                                           onChange={handleChange}/>

                                    {formData?.Units.map((row, index) => (
                                        <div className="row my-1" key={index}>
                                            <div className="col-6">
                                                <div className="input-group">
                                                    <span className="input-group-text">{selectedCategoryUnit}</span>
                                                    <input type="text" id="Name" name="Name"
                                                           className="form-control"
                                                           defaultValue={row.Name}
                                                           placeholder="000" onChange={event => {
                                                        event.currentTarget.value = event.currentTarget.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');
                                                        handleUnitChange(index, 'Name', event.currentTarget.value);
                                                    }}/>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="input-group">
                                                    <span className="input-group-text">€</span>
                                                    <input type="text" id="Price" name="Price"
                                                           className="form-control"
                                                           defaultValue={row.Price}
                                                           placeholder="00.00" onChange={event => {
                                                        event.currentTarget.value = event.currentTarget.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');
                                                        handleUnitChange(index, 'Price', event.currentTarget.value);
                                                    }}/>

                                                </div>
                                            </div>
                                        </div>
                                    ))}

                                    <span role="button" aria-label="add" className="icon m-1" onClick={addRow}
                                          style={{width: "40px", height: "40px"}}>➕</span>
                                    <span role="button" aria-label="delete"
                                          className={productToEdit?.Units.length === 1 ? "icon m-1 d-none" : "icon m-1"}
                                          onClick={(e) => {
                                              e.preventDefault();
                                              deleteRow();
                                          }}>➖</span>
                                </div>
                            </div>

                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={closeAndCleanForm}>Close
                            </button>
                            <button type="submit" className="btn btn-primary">Saglabāt</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ProductModalV1;