import axios from "axios";

let baseURL:string;

if (window.location.hostname === "localhost") {
    baseURL = "http://localhost:8011";
} else {
    //baseURL = "http://13.50.65.22:8011";
    baseURL = "https://h3efdyqzt1.execute-api.eu-north-1.amazonaws.com";
}

export default axios.create({
    baseURL: baseURL,
    responseType: 'json',
    timeout: 10000,
    //withCredentials: true,
    headers: {
        'Content-Type': 'application/json'
    }
});