import React, { useState} from 'react';
import './Login.css';
import { getUserRoleFromToken, login } from "../services/authService";
import { useNavigate } from 'react-router-dom';

const Login: React.FC = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const handleLogin = async (event: React.FormEvent) => {
        event.preventDefault();
        try {
            await login(username, password);
            const role = getUserRoleFromToken();

            if (role === 'Admin') {
                navigate('/admin');
            } else if (role === 'User') {
                navigate('/user');
            } else {
                navigate('/login');
            }
        } catch (err) {
            if (err instanceof Error) {
                setError(err.message);
            }
        }
    };

    return (
        <div className="container login-container">
            <div className="row ">
                <div className="col-md-4 col-sm-12 logo text-center">
                    <img className="m-2" src="KERMAN-LOGO_MELNS.png" alt="Logo" style={{maxWidth: '150px'}} />
                </div>
                <div className="col-md-8 col-sm-12 bg-white p-2 login_form">
                    {/*<h2 className="text-center">Apply</h2>*/}

                    {error && (
                        <div className="alert alert-warning alert-dismissible fade show" role="alert">
                            <strong>{error}</strong>
                        </div>
                    )}

                    <form onSubmit={handleLogin}>
                        <div className="form-group">
                            <label>Username</label>
                            <input type="email" id="username" name="username" className="form-control"
                                   value={username} onChange={e => setUsername(e.target.value)} />
                        </div>
                        <div className="form-group">
                            <label>Password</label>
                            <input type="password" id="password" name="password" className="form-control" value={password} onChange={e => setPassword(e.target.value)} />
                        </div>
                        <div className="text-center m-2">
                            <button type="submit" className="btn btn-primary btn-block">Log in</button>
                        </div>
                    </form>

                    <p className="text-center">
                        No account? <a href="/register">Sign up</a>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Login;