import React, { ReactNode, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface AdminLayoutProps {
    children: ReactNode;
}
const AdminLayout: React.FC<AdminLayoutProps> = ({ children }) => {

    const navigate = useNavigate();
    const [selectedLink, setSelectedLink] = useState('');

    function handleLogout() {
        localStorage.removeItem('token');
        navigate('/login');
    }

    function handlePasutijumi() {
        navigate('/admin/pasutijums');
        setSelectedLink('pasutijums');
    }
    function handleLietotaji() {
        navigate('/admin/lietotaji');
        setSelectedLink('lietotajs');
    }
    function handleCatalog() {
        navigate('/admin/katalogs');
        setSelectedLink('katalogs');
    }

    function handleKatalogs() {
        navigate('/user');
    }

    return (
        <div>
            <div className="fluid px-2" style={{ backgroundColor: '#008080' }}>
                <nav className="navbar navbar-expand-sm ">
                    <div className="col-md-3 col-sm-12 ms-3">
                        <img src="KERMAN-LOGO_MELNS.png" alt="Logo" style={{maxHeight: '25px'}}/>
                    </div>

                    <ul className="nav col-md-7 col-sm-12 mb-2 justify-content-center mb-md-0">
                        <li className="nav-item">
                            <button className="nav-link px-2 text-white" id="catalogs"
                                    onClick={handleKatalogs}>Katalogs
                            </button>
                        </li>
                    </ul>

                    <div className="col-md-2 col-sm-12 text-end me-1">
                        <button className="btn btn-danger me-3" onClick={handleLogout}>Logout</button>
                    </div>
                </nav>
            </div>

            <div className="container-fluid">
                <div className="row flex-nowrap">
                    <div className="col-auto col-md-3 col-xl-2 px-sm-2 px-0 bg-dark" style={{maxWidth: '200px'}}>
                        <div
                            className="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
                            <ul className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start"
                                id="menu">
                                <li className="nav-item">
                                    <button className="nav-link align-middle px-0" onClick={handlePasutijumi}>
                                        <i className="fs-5 bi-basket text-white me-2"></i>
                                        <span
                                            className={`ms-1 d-none d-sm-inline  fs-5 ${selectedLink === 'pasutijums' ? 'text-success' : 'text-white'}`}
                                            id="katalogs">Pasūtījumi</span>
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button className="nav-link px-0 align-middle" onClick={handleCatalog}>
                                        <i className="fs-5 bi-box-seam text-white me-2"></i>
                                        <span className={`ms-1 d-none d-sm-inline  fs-5 ${selectedLink === 'katalogs' ? 'text-success' : 'text-white'}`}
                                              id="katalogs">Katalogs</span>
                                    </button>
                                </li>

                                <li className="nav-item">
                                    <button className="nav-link px-0 align-middle" onClick={handleLietotaji}>
                                        <i className="fs-5 bi-briefcase text-white me-2"></i>
                                        <span className={`ms-1 d-none d-sm-inline  fs-5 ${selectedLink === 'lietotajs' ? 'text-success' : 'text-white'}`}
                                              id="lietotajs">Lietotāji</span>
                                    </button>
                                </li>


                            </ul>

                        </div>
                    </div>

                    <div className="col py-3">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminLayout;