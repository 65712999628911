import React from 'react';

const Default: React.FC = () => {



    return (
        <div className="container login-container">
            <h5>Pamat lapa</h5>
        </div>
    );
};

export default Default;
