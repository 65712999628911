import axios from 'axios';
import http from "../config/http-common";

function parseJwtToken(token: string | null) {
    const parsedToken = token ? JSON.parse(window.atob(token.split('.')[1])) : null;
    const userRole = parsedToken && parsedToken.role
        ? parsedToken.role.charAt(0).toUpperCase() + parsedToken.role.slice(1)
        : null;

    return { role: userRole };
}

export const login = async (username: string, password: string) => {

    const url = '/api/login';

    const corsHeaders = {'Content-Type': 'application/json'};

    await http.post(url, {
        username,
        password
    }, {
        headers: corsHeaders
    })
        .then((response) => {
            if (response.status === 200) {
                const token = response.data['token'];
                localStorage.setItem('token', token);
                const refreshToken = response.data['refreshToken'];
                localStorage.setItem('refreshToken', refreshToken);
                const user = parseJwtToken(token);
                return { user, token, refreshToken };
            }
        })
        .catch((error) => {
            throw new Error('Invalid credentials');
        })
};

export const UseRefreshToken = async () => {
    const token = localStorage.getItem('token');
    const refreshToken = localStorage.getItem('refreshToken');

    const corsHeaders = {
        'Content-Type': 'application/json',
        'Token': token,
        'Refresh-Token': refreshToken
    };

    await http.get('/api/login/refresh',{
        headers: corsHeaders
    }).then(response => {
        const newToken = response.data['token']; // Updated here
        localStorage.setItem('token', newToken);

        const newRefreshToken = response.data['refreshToken']; // Updated here
        localStorage.setItem('refreshToken', newRefreshToken);
        if(response.status !== 200){
            throw new Error('Invalid credentials');
        }
    }).catch((error) => {
        throw new Error('Invalid credentials');
    })

};

export const validateTokenOnRequests = () => {
    const token = localStorage.getItem('token');
    if (!token) {
        throw new Error('No token found');
    }
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

export const isLoggedIn = () => {
    const token = localStorage.getItem('token');
    return !!token;
};

export const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    window.location.reload();
};

export const getUserRole = () => {
    const role = localStorage.getItem('role');
    if (role) {
        return role;
    }
    return null;
};

export const getUserRoleFromToken = () => {
    const token = localStorage.getItem('token');
    if (token) {
        const parsedToken = JSON.parse(atob(token.split('.')[1]));
        const role = parsedToken && parsedToken.role ? parsedToken.role.charAt(0).toUpperCase() + parsedToken.role.slice(1) : null;

        return role;
    }
    return null;
};