import React, {useState, useCallback} from 'react';
import {Button, Modal} from "react-bootstrap";
import http from "../../config/http-common";
import {useNavigate} from "react-router-dom";
import ProductModalV1 from "./ProductModalV1";

type Product = {
    ID: string,
    CategoryID: string,
    Name: string,
    SKU: string,
    ImageLink: string,
    Units: Unit[],
};

type Unit = {
    Id: string,
    Name: string,
    Price: number,
};

interface ModalProps {
    product: Product;
    categoryUnit: string;
    handleCategoryClick: (categoryId: string) => void;
    refreshPage?: () => void;
}

const ProductComponent: React.FC<ModalProps> = ({product, categoryUnit, handleCategoryClick, refreshPage}) => {
    const navigate = useNavigate();
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
    const [showEditProductModal, setShowEditProductModal] = useState(false);

    const renderKey = useCallback(() => Math.random().toString(), []);

    const handleCancelDelete = () => {
        setShowDeleteConfirmationModal(false);
    };

    const handleShowEditProduct = () => {
        setShowEditProductModal(true);
    };

    const handleCloseEditProduct = () => {
        setShowEditProductModal(false);
        refreshPage?.();
    };

    const askDeleteConfirmation = () => {
        setShowDeleteConfirmationModal(true);
    };

    const handleConfirmDelete = () => {
        handleProductDelete(product?.ID);
        setShowDeleteConfirmationModal(false);
    };

    const handleProductDelete = async (id: string) => {
        try {
            const token = localStorage.getItem('token');
            await http.delete('/api/product', {
                headers: {
                    'Token': token
                },
                params: {productID: id}
            });
            product && handleCategoryClick(product.CategoryID);
        } catch (error) {
            navigate('/login');
        }
    };

    return (
        <div className="col-12 m-1">
            <div className="row border">
                <div className="col-lg-1 col-xl-1 text-center">
                    <img className=" m-1" style={{maxHeight: '90px', maxWidth: '90px'}}
                         src={product?.ImageLink || 'https://via.placeholder.com/150?text=No+image'} alt=""/>
                </div>
                <div className="col-lg-5 col-xl-6">
                    <div className="p-1 m-1" style={{width: '100%', backgroundColor: '#F0F0F0'}}>
                        <h5 className="m-1">{product?.Name}</h5>
                    </div>
                    <p>SKU: {product?.SKU}</p>
                </div>
                <div className="col-lg-3 col-xl-4">
                    <div className="row">
                        {
                            product?.Units?.map((unit: Unit, index: number) => (
                                <div key={unit.Id} className="col-md-3 col-lg-2 col-xl-5 border m-1">
                                    <div>{unit.Name} {categoryUnit}. = {unit.Price} €</div>
                                </div>
                            ))
                        }
                    </div>

                </div>
                <div className="col-lg-1 col-xl-1 text-end">
                    <i role="button" className="bi bi-pencil m-1" onClick={handleShowEditProduct}></i>
                    <i role="button" className="bi bi-x-lg m-1" onClick={askDeleteConfirmation}></i>

                    <Modal show={showDeleteConfirmationModal} onHide={handleCancelDelete}>
                        <Modal.Header closeButton>
                            <Modal.Title>Dzēšanas apstiprinājums</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Vai vēlaties dzēst izvēlēto Produktu?</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleCancelDelete}>Nē</Button>
                            <Button variant="primary" onClick={handleConfirmDelete}>Jā</Button>
                        </Modal.Footer>
                    </Modal>

                    <ProductModalV1
                        productToEdit={product || null}
                        editMode={true}
                        show={showEditProductModal}
                        key={renderKey()}
                        selectedCategoryUnit={categoryUnit || ''}
                        selectedCategoryId={product.CategoryID || ''}
                        closeHandler={handleCloseEditProduct}
                        reloadCatalog={() => handleCategoryClick(product.CategoryID || '')}/>
                </div>
            </div>
        </div>
    );
}

export default ProductComponent;