import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import ProtectedRoute from './services/ProtectedRoute'


import Lietotaji from './components/admin/Lietotaji';
import Default from './components/admin/Default';

import Login from './components/Login';
import UserLayout from './layouts/UserLayout';
import AdminLayout from './layouts/AdminLayout';
import Catalog from "./components/admin/Catalog";
import DefaultUser from "./components/public/DefaultUser";
import Register from "./components/Register";
import Pasutijumi from "./components/admin/Pasutijumi";



const AppRouter: React.FC = () => {
    return (
        <Router>
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route
                    path="/admin"
                    element={
                        <ProtectedRoute roles={['Admin']}>
                            <AdminLayout>
                                <Default />
                            </AdminLayout>
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/admin/pasutijums"
                    element={
                        <ProtectedRoute roles={['Admin']}>
                            <AdminLayout>
                                <Pasutijumi />
                            </AdminLayout>
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/admin/lietotaji"
                    element={
                        <ProtectedRoute roles={['Admin']}>
                            <AdminLayout>
                                <Lietotaji />
                            </AdminLayout>
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/admin/katalogs"
                    element={
                        <ProtectedRoute roles={['Admin']}>
                            <AdminLayout>
                                <Catalog />
                            </AdminLayout>
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/user"
                    element={
                        <ProtectedRoute roles={['Admin', 'User']}>
                            <UserLayout>
                                <DefaultUser doFetchData={false}/>
                            </UserLayout>
                        </ProtectedRoute>
                    }
                />
                {/* ... other routes */}
                {/* Catch-all route */}
                <Route path="*" element={<Navigate to="/login" replace />} />
            </Routes>
        </Router>
    );
};

export default AppRouter;