import React, {useCallback, useEffect, useState} from 'react';
import UserProductComponent from "./UserProductComponent";
import http from "../../config/http-common";
import {Spinner} from 'react-bootstrap';
import {logout, UseRefreshToken} from "../../services/authService";

type Category = {
    Id: string,
    Name: string,
    Unit: string,
    Discount: string,
    Products: Product[] | null,
    CategoryDiscount: number,
    CategoryPrice: number,
};

type Product = {
    ID: string,
    CategoryID: string,
    Name: string,
    SKU: string,
    ImageLink: string,
    Units: Unit[],
    ProductDiscount: number,
    ProductPrice: number,
};


type Unit = {
    id: string,
    Name: string,
    Price: number,
    BasketUnit: ProductUnit
};
type ProductUnit = {
    UnitId: string,
    Quantity: number,
    Price: number,
};

interface DefaultUserProps {
    reloadBasket?: () => void;
    doFetchData: boolean;
}

const DefaultUser: React.FC<DefaultUserProps> = ({reloadBasket, doFetchData}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [categories, setCategories] = useState<Category[]>([]);

    const fetchData = useCallback(async () => {
        setIsLoading(true);
        if (reloadBasket) {
            reloadBasket();
        }
        const token = localStorage.getItem('token');
        await http.get('/api/public/product', {
            headers: {
                'Token': token
            }
        }).then(response => {
            if (Array.isArray(response.data) && response.data.length) {
                setCategories(response.data);
            }
        }).catch((error: { response: { status: number; }; }) => {
            if (error.response) {
                const status = error.response.status;
                if (status === 401) {
                    UseRefreshToken().then(() => {
                        fetchData();
                    });
                }
            } else {
                logout();
            }
        }).finally(() => {
            setIsLoading(false);
        });
    }, [reloadBasket]);

    useEffect(() => {
        if (doFetchData) {
            fetchData();
        }
    }, [doFetchData, fetchData]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <div className="container">
            {isLoading && (
                <div style={{position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 9999}}>
                    <Spinner animation="border"/>
                </div>
            )}
            <div className="accordion" id="accordionExample">
                {
                    categories.map((category, index) => (
                        <div className="accordion-item" key={category.Id}>
                            <h2 className="accordion-header " id={`heading${index}`}>
                                <button
                                    style={{backgroundColor: index % 2 === 0 ? 'white'  : 'white'}}

                                    className={`accordion-button collapsed `}
                                    type="button" data-bs-toggle="collapse"
                                    data-bs-target={`#collapse${index}`} aria-expanded="false"
                                    aria-controls={`collapse${index}`}>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-9">
                                                <b>{category.Name}</b>
                                            </div>
                                            <div className="col-3">
                                                {category.CategoryPrice !== 0 && (
                                                    <>
                                                        <span>Kopā: </span>
                                                        {category.CategoryDiscount !== 0 && (
                                                            <span style={{color: 'red'}}><del>
                                                                {(category.CategoryPrice).toFixed(2)} €
                                                            </del></span>
                                                        )}
                                                        <span className="ms-1"
                                                              style={{fontSize: '1em'}}><b>{(category.CategoryPrice - category.CategoryDiscount).toFixed(2)} €</b></span>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </button>
                            </h2>
                            <div id={`collapse${index}`} className="accordion-collapse collapse"
                                 aria-labelledby={`heading${index}`}
                                 data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    {category.Products?.map((product, productIndex) =>
                                        <UserProductComponent
                                            key={productIndex}
                                            product={product}
                                            unitName={category.Unit}
                                            refreshData={fetchData}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>

        </div>
    );
};

export default DefaultUser;