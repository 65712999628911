import React,{ Component } from 'react';
import http from "../../config/http-common";


type User = {
    Id: string,
    UserId: string,
    Name_lastname: string,
    Phone: string,
    Email: string,
    Address: string,
    RegNr: string,
    Company: string,
    PvnNr: string,
    CompanyAddress: string,
    WWW: string
};

class LietotajsModal extends Component<{ show: boolean, onClick: () => void, selectedUser: User, reloadData: () => void }, { user: User, initialUser: User, show: boolean }> {
    private formRef = React.createRef<HTMLFormElement>();

    constructor(props: { show: boolean, onClick: () => void, selectedUser: User, reloadData: () => void }) {
        super(props);
        this.state = { user: this.props.selectedUser, initialUser: this.props.selectedUser, show: this.props.show };
        this.updateUser = this.updateUser.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    handleClose() {
        this.props.onClick();
        this.props.reloadData();
    }

    updateUser(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        const formData = new FormData(this.formRef.current!);
        const updatedUser = { ...this.state.user };
        Array.from(formData.entries()).forEach(entry => {
            const key = entry[0] as keyof User;
            updatedUser[key] = entry[1] as string;
        });

        if (JSON.stringify(this.state.initialUser) === JSON.stringify(updatedUser)) {
            alert('No changes to update');
            return;
        }

        this.setState({ user: updatedUser });
        const token = localStorage.getItem('token');
        if(this.props.selectedUser.Id === "" ) {
            http.post('/api/users', updatedUser, {
                headers: {
                    'Token': token
                }
            }).then(() => {
                this.handleClose();
            }).catch(error => {
                alert('Failed to store user data');
            });
            return;
        }


        http.put('/api/users', updatedUser, {
            headers: {
                'Token': token
            }
        }).then(() => {
            this.handleClose();
        }).catch(error => {
            alert('Failed to update user data');
        });
    }

    componentDidUpdate(prevProps: { show: boolean }) {
        if (this.props.show !== prevProps.show && this.props.show) {
            this.formRef.current?.reset();
            let emptyUser = {
                Id: '',
                UserId: '',
                Name_lastname: '',
                Phone: '',
                Email: '',
                Address: '',
                RegNr: '',
                Company: '',
                PvnNr: '',
                CompanyAddress: '',
                WWW: ''
            };
            this.setState({ user: emptyUser, initialUser: emptyUser });
        }
    }

    render() {

        return <div className="modal" tabIndex={-1} style={{display: this.props.show ? "block" : "none"}}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">`
                        <h5 className="modal-title">User Details</h5>
                        <button type="button" className="btn-close" onClick={this.props.onClick}></button>
                    </div>
                    <div className="modal-body">
                        <form ref={this.formRef} onSubmit={this.updateUser} id="submitForm">
                            <input type="hidden" name="Id"
                                   defaultValue={this.props.selectedUser.Id}/> {/* Hidden field for ID value */}
                            <div className="form-group">
                                <label htmlFor="Name_lastname">Name Lastname</label>
                                <input type="text" className="form-control" id="Name_lastname" name="Name_lastname"
                                       defaultValue={this.props.selectedUser.Name_lastname} required/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="Phone">Phone</label>
                                <input type="tel" className="form-control" id="Phone" name="Phone"
                                       defaultValue={this.props.selectedUser.Phone} required/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="Email">Email</label>
                                <input type="email" className="form-control" id="Email" name="Email"
                                       defaultValue={this.props.selectedUser.Email} required/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="Address">Address</label>
                                <input type="text" className="form-control" id="Address" name="Address"
                                       defaultValue={this.props.selectedUser.Address} required/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="RegNr">Reg Nr.</label>
                                <input type="text" className="form-control" id="RegNr" name="RegNr"
                                       defaultValue={this.props.selectedUser.RegNr} required/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="Company">Company</label>
                                <input type="text" className="form-control" id="Company" name="Company"
                                       defaultValue={this.props.selectedUser.Company} required/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="PvnNr">VAT Nr.</label>
                                <input type="text" className="form-control" id="PvnNr" name="PvnNr"
                                       defaultValue={this.props.selectedUser.PvnNr} required/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="CompanyAddress">Company Address</label>
                                <input type="text" className="form-control" id="CompanyAddress" name="CompanyAddress"
                                       defaultValue={this.props.selectedUser.CompanyAddress} required/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="WWW">Website</label>
                                <input type="text" className="form-control" id="WWW" name="WWW"
                                       defaultValue={this.props.selectedUser.WWW}/>
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={this.props.onClick}>Close</button>
                        <button type="submit" form="submitForm" className="btn btn-primary">Update</button>
                    </div>
                </div>
            </div>
        </div>;
    }
}

export default LietotajsModal;