import React, {useState, useCallback, useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import UnitComponent from "./UnitComponent";

type Product = {
    ID: string,
    CategoryID: string,
    Name: string,
    SKU: string,
    ImageLink: string,
    Units: Unit[],
    ProductDiscount: number,
    ProductPrice: number,
};

type BasketItems = {
    CategoryId: string,
    UnitId: string,
    Products: BasketProduct[],
    TotalPrice: number,
    TotalDiscount: number,
}
type BasketProduct = {
    ProductId: string,
    UnitList: ProductUnit[]
}


type Unit = {
    id: string,
    Name: string,
    Price: number,
    BasketUnit: ProductUnit
}
type ProductUnit = {
    UnitId: string,
    Quantity: number,
    Price: number,
}


interface ModalProps {
    product: Product
    unitName:string
    refreshData: Function
}

const ProductComponent: React.FC<ModalProps> = ({product, unitName, refreshData}) => {
    const navigate = useNavigate();
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
    const [showEditProductModal, setShowEditProductModal] = useState(false);
    const [isChanged, setIsChanged] = useState(false);

    // call refreshData function when unit list is changed
    useEffect(() => {
        if(isChanged){
            refreshData();
            setIsChanged(false);
        }
    }, [isChanged]);

    return (
        <div className="container mt-3">
            <div className="card">
                <div className="row g-0">
                    <div className="col-md-2 ">
                        <div className="text-center mt-2  " >
                            <img src={product.ImageLink} className="" style={{maxWidth: '130px'}} alt="Product"/>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="row mx-1">
                            <div className="col-12 bg-secondary-subtle">
                                <b>{product.Name}</b>
                            </div>
                        </div>
                        <p className="card-text ms-1">SKU: {product.SKU}</p>
                    </div>
                    <div className="col-md-4">
                        <div className="row mx-1">
                            <div className="col-12 text-end bg-secondary-subtle">
                                &nbsp;
                                {product.ProductPrice !== 0 && (
                                    <>
                                        <span>Kopā: </span>
                                        {product.ProductDiscount !== 0 && (
                                            <span style={{color: 'red'}}><del>{(product.ProductPrice).toFixed(2)} €</del></span>
                                        )}
                                        <span className="ms-1" style={{fontSize: '1em'}}><b>{(product.ProductPrice - product.ProductDiscount).toFixed(2)} €</b></span>
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 mt-1">
                                {product.Units?.map(unit => {
                                    return (
                                        <UnitComponent
                                            UnitData={unit}
                                            ProductId={product.ID}
                                            UnitName={unitName}
                                            onDataRefresh={() => {
                                                setIsChanged(true);
                                            }}/>
                                    );
                                })}
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProductComponent;