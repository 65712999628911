import React, { useEffect, useState } from 'react';
import AppRouter from './AppRouter';


const App: React.FC = () => {



  return (
    <div className="App">
      <AppRouter />
    </div>
  );
}

export default App;