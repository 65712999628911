import React, {useEffect, useState} from 'react';
import discountTypesData from '../../resources/discount.json';
import http from "../../config/http-common";


interface ModalProps {
    show: boolean;
    mode: "edit" | "create";
    onClick: () => void;
    onSuccess: () => void;
    selectedCategory?: {
        Id: string;
        Name: string;
        Unit: string;
        Discount: string;
    };
}
interface FormData {
    Id: string;
    Name: string;
    Unit: string;
    Discount: string;
}

const LietotajsModalV1: React.FC<ModalProps> = ({show, mode, onClick, onSuccess, selectedCategory}) => {
    const [formData, setFormData] = useState<FormData>({Id:'', Name:'', Unit:"", Discount:""})

    useEffect(() => {
        if (mode === "create") {
            setFormData({Id:"", Name:"", Unit:"", Discount:""});
        } else {
            setFormData(selectedCategory!);
        }
    }, [mode, selectedCategory]);

    const discountTypes = discountTypesData;

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };


    const createNewCategory = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const token = localStorage.getItem('token');

        if (mode === 'create') {
            if (formData) {
                 http.post('/api/category', formData, {
                    headers: {'Token': token}
                }).then((result) => {
                    if (result.status === 200) {
                        onSuccess();
                        onClick();
                    }
                });
            }
        }
        if (mode === 'edit') {
            if (formData) {
                http.put('/api/category', formData, {
                    headers: {'Token': token}
                }).then((result) => {
                    if (result.status === 200) {
                        onSuccess();
                        onClick();
                    }
                });
            }
        }
    }

    return (
        <div className="modal" tabIndex={-1} style={{display: show ? "block" : "none"}}>
            <div className="modal-dialog">
                <div className="modal-content bg-light">
                    <div className="modal-header">
                        <h5 className="modal-title">{mode === "edit" ? "Labot kategoriju" : "Jauna Kategorija"}</h5>
                        <button type="button" className="btn-close" onClick={onClick}></button>
                    </div>
                    <div className="modal-body">
                        <form onSubmit={createNewCategory}>
                            <div className="form-group">
                                <input
                                    type="hidden"
                                    id="Id"
                                    name="Id"
                                    defaultValue={mode === "edit" ? selectedCategory?.Id : ""}/>
                                <label>Kategorijas Nosaukums:</label>
                                <input
                                    type="text"
                                    id="Name"
                                    name="Name"
                                    className="form-control"
                                    value={formData.Name}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="row justify-content-between m-1">
                                <div className="form-group col-md-6">
                                    <label>Mērvienība:</label>
                                    <select
                                        className="form-control"
                                        id="Unit"
                                        name="Unit"
                                        value={formData.Unit}
                                        onChange={handleChange}
                                    >
                                        <option value=""></option>
                                        <option value="mm">mm</option>
                                        <option value="grits">grits</option>
                                        <option value="pcs">gb</option>
                                    </select>
                                </div>
                                <div className="form-group col-md-6">
                                    <label>Atlaides veids:</label>
                                    <select
                                        className="form-control"
                                        id="Discount"
                                        name="Discount"
                                        value={formData.Discount}
                                        onChange={handleChange}
                                    >
                                        <option value=""></option>
                                        {
                                            discountTypes.map((discount, index) =>
                                                <option key={index}
                                                        value={discount.discountId}>{discount.discountType}</option>
                                            )
                                        }
                                    </select>
                                </div>

                                <div className="modal-footer">
                                    {mode === "edit" ?
                                        <button type="submit" className="btn btn-primary">Saglabāt</button>

                                        :
                                        <button type="submit" className="btn btn-primary">Izveidot</button>
                                    }
                                    <button type="button" className="btn btn-secondary"
                                            onClick={onClick}>Close
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default LietotajsModalV1;