// ProtectedRoute.tsx
import React from 'react';
import { Navigate } from 'react-router-dom';
import { isLoggedIn, getUserRoleFromToken } from './authService';

interface ProtectedRouteProps {
    roles: string[]; // Array of roles allowed to access the route
    children: JSX.Element; // Child component to render
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ roles, children }) => {
    const userRole = getUserRoleFromToken();
    const isAuthorized = isLoggedIn() && userRole !== null && roles.includes(userRole);

    return isAuthorized ? children : <Navigate to="/login" replace />;
};


export default ProtectedRoute;
