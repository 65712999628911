import React, {useCallback, useEffect, useState} from 'react';
import http from "../../config/http-common";

type BasketItem = {
    ProductId: string,
    UnitId: string,
    Quantity: number,
    Price: number,
    Discount: number,
}


type Unit = {
    id: string,   // Updated from Id to id
    Name: string,
    Price: number,
    BasketUnit: ProductUnit
}
type ProductUnit = {
    UnitId: string,
    Quantity: number,
    Price: number,
}

interface ComponentProps {
    ProductId: string
    UnitName: string
    UnitData: Unit
    onDataRefresh: () => void // <-- Adding a new function prop which the parent can define
}

const UnitComponent: React.FC<ComponentProps> = ({UnitData, ProductId, UnitName, onDataRefresh}) => {

    const originalQuantity = UnitData.BasketUnit ? UnitData.BasketUnit.Quantity.toString() : "";
    const [inputQuantity, setInputQuantity] = useState(originalQuantity);

    const handleKeyDown = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
        if (!/[0-9]/.test(event.key) && event.key !== "Backspace" && event.key !== "Delete") {
            event.preventDefault();
        }
    }, []);

    const handleInputChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setInputQuantity(event.target.value);
    }, []);

    const handleButtonClick = useCallback(() => {
        const data = {
            ProductId,
            UnitId: UnitData?.id,
            Quantity: parseInt(inputQuantity),
        };

        const token = localStorage.getItem('token');
        http.post('/api/public/basket', data, {
            headers: {
                'Token': token
            }
        }).then(response => {
            onDataRefresh(); // <-- Just reference the new props function here
         });
    }, [ProductId, UnitData.id, inputQuantity]);

    return (
        <div className="row gx-2 gy-3">
            <div className="col-5 text-end pt-1">
                <p>{UnitData.Name} {UnitName} - {UnitData.Price.toFixed(2)} €</p>
            </div>
            <div className="col-3">
                <input type="text" className="form-control"
                       style={{width: '5em'}}
                       value={inputQuantity}
                       placeholder="0"
                       onKeyDown={handleKeyDown}
                       onChange={handleInputChange}/>
            </div>
            <div className="col-4">
                {originalQuantity !== inputQuantity && (
                    <button type="button" className="btn btn-outline-success" onClick={handleButtonClick}>OK</button>
                )}
                {originalQuantity == inputQuantity && UnitData.BasketUnit.Quantity !== 0 && (
                    <strong>
                        {UnitData.BasketUnit.Price ? (UnitData.BasketUnit.Price).toFixed(2) : 0} €
                    </strong>
                )}
            </div>
        </div>
    )
}

export default UnitComponent;