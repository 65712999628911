import React, { useEffect, useState, useCallback } from 'react';
import http from "../../config/http-common";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import LietotajsModal from './LietotajsModal'
import Modal from "react-bootstrap/Modal";
import {Hash} from "node:crypto";

type User = {
    Id: string,
    UserId: string,
    Name_lastname: string,
    Phone: string,
    Email: string,
    Address: string,
    RegNr: string,
    Company: string,
    PvnNr: string,
    CompanyAddress: string,
    WWW: string
    Role:string
}

const Lietotaji: React.FC = () => {
    const token = localStorage.getItem('token');

    const [users, setUsers] = useState<User[]>([]);
    const [show, setShow] = useState(false);
    const [showDrosiba, setShowDrosiba] = useState(false);
    const [selectedUser, setSelectedUser] = useState<User | null>(null);
    const handleClose = () => setShow(false);
    const handleDrosibaClose = () => setShowDrosiba(false);
    const handleShow = () => setShow(true);
    const handlDrosibaeShow = () => setShowDrosiba(true);
    const navigate = useNavigate();
    const [password, setPassword] = useState<string>('');
    const [selectedRole, setSelectedRole] = useState<string>('');

    const fetchData = useCallback(async () => {

        try {
            const result = await http.get('/api/users', {
                headers: {
                    'Token': token
                }
            });
            setUsers(result.data);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                navigate('/login');
            }
        }
    }, [navigate]);

    const updateUser = async () => {
        if (!selectedUser) return;

        const updatedUser = {
            Id: selectedUser.UserId,
            password: password,
            role: (selectedRole || selectedUser?.Role), /* Modified code: role from selectedRole state */
        };

        await http.put(`api/users/login`, updatedUser, {
            headers: {
                'Token': token
            }
        });
        setShowDrosiba(false);
        fetchData();
    };

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleClick = (user: User) => {
        setSelectedUser(user);
        handleShow();
    };

    const handleDrosibaClick = (user: User) => {
        setSelectedUser(user);
        handlDrosibaeShow()
        setPassword('');
    }

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
    };
    const handleRoleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedRole(e.target.value);
    };

    const handlePasswordGeneration = () => {
        const characters : string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789#$%';
        let password : string = '';
        const characterLength : number = characters.length;
        for ( let i = 0; i < 8; i++ ) {
            password += characters.charAt(Math.floor(Math.random() * characterLength));
        }
        setPassword(password);
    };

    return (
        <div className="container mt-4">
            <div className="row">
                <div className="col-3">
                    <h2>Lietotāji</h2>
                </div>
            </div>

            <table className="table">
                <thead>
                <tr>
                    <th scope="col">Uzņēmuma</th>
                    <th scope="col">Vārds Uzvārds</th>
                    <th scope="col">Reģistrācijas nr</th>
                    <th scope="col">E-pasta</th>
                    <th scope="col">Telefona numurs</th>
                    <th scope="col">Mājaslapas</th>
                    <th scope="col"></th>
                </tr>
                </thead>
                <tbody>
                {users.map((user, index) =>
                    <tr key={index}>
                        <td>{user.Company}</td>
                        <td>{user.Name_lastname}</td>
                        <td>{user.RegNr}</td>
                        <td>{user.Email}</td>
                        <td>{user.Phone}</td>
                        <td>{user.WWW}</td>
                        <td>
                            <button className="btn btn-danger" onClick={() => handleClick(user)}>Dati</button>
                            <button className="btn btn-danger ms-1" onClick={() => handleDrosibaClick(user)}>Drošība</button>
                        </td>
                    </tr>
                )}
                </tbody>
            </table>

            {selectedUser &&
            <LietotajsModal show={show} onClick={handleClose} selectedUser={selectedUser} reloadData={fetchData}/>
            }

            <Modal className="modal" show={showDrosiba} onHide={handleDrosibaClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Paroles/Lomas maiņa</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>{selectedUser?.Company || selectedUser?.Name_lastname}</h5>
                    <hr/>
                    <div className="row">
                        <div className="col-6">
                            <input type="text" id="password" name="password" className="form-control" value={password} onChange={handlePasswordChange}/>
                        </div>
                        <div className="col-6">
                            <button className="btn btn-primary" onClick={handlePasswordGeneration}>Izveidot Paroli</button>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-4 mt-2">
                            <label><b>Lietotāja Loma:</b></label>
                        </div>
                        <div className="col-8">
                            <select
                                className="form-control"
                                id="Role"
                                name="Role"
                                value={selectedRole || selectedUser?.Role}
                                onChange={handleRoleChange}
                            >
                                <option value="user">user</option>
                                <option value="admin">admin</option>
                            </select>
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary" onClick={handleDrosibaClose}>Atcelt</button>
                    <button className="btn btn-primary" onClick={updateUser}>Saglabāt</button>
                </Modal.Footer>
            </Modal>

        </div>
    );
};

export default Lietotaji;